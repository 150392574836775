<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		form(@submit.prevent="submit")
			.widget
				.widget-header
					h1.title Profile Settings
					router-link.btn.btn-secondary.mr-2(:to="{name:'dashboard'}") Cancel
					button.btn.btn-primary(type="submit") Save
						i.la.la-fw.la-save
				.widget-body
					div(style="opacity: 0;width: 0; height: 0; overflow: hidden;")
						input(type="text", name="email")
						input(type="password", name="password")
					h4.widget-section-title Account
					.row
						.col-sm-8
							.form-group
								label Name
								input.form-control(type="text", v-model="user.name")
							.form-group
								label Email
								input.form-control(type="email", v-model="newEmail")
								small.form-text.text-muted Current email is "{{ user.email }}". Leave blank to keep current value
							.form-group
								label Password
								input.form-control(type="password", v-model="newPassword")
								small.form-text.text-muted Leave blank to keep current value
						.col-sm-4
							.form-group.text-center.mt-3
								b-avatar(variant="info", size="14rem")

					h4.widget-section-title Notifications
					.settings-item.mb-2
						b-form-checkbox(v-model="user.notify_offer_status_change") Offer Status Change &nbsp;
							i.la.la-info-circle(v-b-tooltip.hover.right, title="Let me know whenever one of my offers status has been changed")
					.settings-item.mb-2
						b-form-checkbox(v-model="user.notify_offer_po_change") Offer Payout Change &nbsp;
							i.la.la-info-circle(v-b-tooltip.hover.right, title="Let me know whenever one of my offers payout has been changed")
					//.settings-item.mb-2
						b-form-checkbox(v-model="user.notify_offer_manual_edit") Offer Manual Edit &nbsp;
							i.la.la-info-circle(v-b-tooltip.hover.right, title="Let me know whenever anyone edits one of my offers")



</template>
<style lang="scss"></style>
<script>
import Vue from 'vue';

export default {
  name: 'Profile',
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      busy: false,
      newEmail: '',
      newPassword: '',
      user: {},
    };
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.state.user);
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.user);
      data.email = this.newEmail;
      data.password = this.newPassword;

      try {
        await this.$ovReq.post('user/saveProfile', data);
        this.busy = false;
        this.$ovNotify.success('Changes have been saved');
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },
  },
};
</script>
